import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3f6ecfdf&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiFormProgress: require('/codebuild/output/src1764022851/src/med-el-hearpeers/components/Ui/Form/Progress.vue').default,UiAlert: require('/codebuild/output/src1764022851/src/med-el-hearpeers/components/Ui/Alert.vue').default,UiForm: require('/codebuild/output/src1764022851/src/med-el-hearpeers/components/Ui/Form/index.vue').default,UiSpinner: require('/codebuild/output/src1764022851/src/med-el-hearpeers/components/Ui/Spinner/index.vue').default})
