//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getLocalesModel from '~/gql/getLocalesModel'

export default {
  name: 'LayoutTopLanguageSwitcher',
  props: {
    location: {
      type: String,
      default: null,
    },
    typeo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        formLocation: null,
        language: this.$i18n.locale,
      },
      isExpanded: false,
      locales: [],
      currentLocale: null,
    }
  },
  async fetch() {
    const localesModel = await this.$graphcms.request(getLocalesModel)

    const currentLocale = localesModel.localesModels.find(
      (loc) => loc.localeAcronym === this.formData.language
    )

    this.currentLocale = currentLocale

    this.locales = localesModel.localesModels.sort((a, b) => {
      // If title is "English - International", move it to the front
      if (a.title === 'English - International') return -1
      if (b.title === 'English - International') return 1
      // Otherwise, sort alphabetically
      return a.title.localeCompare(b.title)
    })
  },

  computed: {
    groupedLocales() {
      const filtered = this.locales.filter(
        (locale) =>
          locale.localeAcronym !== this.formData.language &&
          locale.showInLanguageSwitch
      )

      const chunks = []
      for (let i = 0; i < filtered.length; i += 6) {
        chunks.push(filtered.slice(i, i + 6))
      }
      return chunks
    },
  },
  watch: {
    id(newId, oldId) {
      this.$fetch()
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    toggleAccordion() {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded) {
        document.addEventListener('click', this.handleClickOutside)
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    },
    handleClickOutside(event) {
      if (this.$refs.languageOptions) {
        const isClickInside = this.$refs.languageOptions.contains(event.target)
        if (!isClickInside) {
          this.isExpanded = false
        }
      }
    },
    getClassNames() {
      if (this.typeo === 'top') {
        return `topheader topapproach`
      } else if (this.typeo === 'bottom') {
        return `topheader bottomapproach`
      }
      return 'topheader'
    },

    onLanguageClick(language) {
      this.formData.language = language
      this.$router.push({
        path: this.$localeResolver.switchLocalePath(language),
      })
      this.$store.commit(
        'locale/setCurrentLocation',
        this.formData.formLocation
      )
      this.isExpanded = false
    },
  },
}
